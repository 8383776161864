import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Icons from "../components/Icons"
import logo from "../images/INMAGIK_COLOR.svg"
import get from "lodash/get"

const WorkPage = ({ data, intl }) => {
  const { allMarkdownRemark } = data

  const blogData = get(allMarkdownRemark, "edges", []).map(x => x.node)
  //console.log("blogData", blogData)

  return (
    <Layout>
      <SEO title="Technology - INMAGIK" />
      <div className="jumbo-title jumbo-small p-4 container-fluid text-center bg-black">
        <div className="display-3 font-200 text-white">- Blog -</div>
        <div className="row my-4">
          <div className="col-md-6 offset-md-3 text-center">
            <p className="lead font-200 text-white">
              A collection of articles, tutorials and ideas from our team
            </p>
          </div>
        </div>
      </div>

      <div className="main-content container p-3">
        {blogData.map(node => (
          <Link key={node.id} to={`/blog/${node.frontmatter.path}`}>
            <div className="card mb-3 d-flex flex-row">
              <div className="p-2 border-right" style={{ width: 200 }}>
                <Icons
                  names={get(node, "frontmatter.icons", "").split(",")}
                ></Icons>
              </div>
              <div className="card-body">
                <h4 className="font-300">{node.frontmatter.title}</h4>
                <p className="font-200 text-dark">
                  by <i>{node.frontmatter.author}</i> - {node.frontmatter.date}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Layout>
  )
}

export default injectIntl(WorkPage)

export const query = graphql`
  query {
    file {
      id
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blog)/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        html
      }
      edges {
        node {
          id
          html
          frontmatter {
            title
            path
            author
            date(formatString: "DD MMM YYYY")
            icons
          }
        }
      }
    }
  }
`
