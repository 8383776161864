import React from "react"

export default function Icons({ names = [], fixedWidth }) {
  const className = fixedWidth ? "w-100 h-100 d-flex justify-content-center" : "w-100 h-100 d-flex"
  const iconDivStyle = fixedWidth ? { width: fixedWidth } : { flex: 1 }

  return (
    <div className={className}>
      {names.map((name, i) => {
        return (
          <div
            className="h-100 mx-2"
            style={{
              background: `url(/icons/${name}.png) no-repeat`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              ...iconDivStyle,
            }}
          ></div>
        )
      })}
    </div>
  )
}
